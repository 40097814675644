'use client';

import { urlLocaleMatcherRegex } from '@akinon/next/utils';
import { countries } from '@theme/preferences';
import settings from '@theme/settings';
import { useEffect } from 'react';

const Page = () => {
  useEffect(() => {
    const url = window.location;

    const hostWithoutProtocol = url.hostname
      .replace('https://', '')
      .replace('http://', '');

    const isDev = process?.env?.NODE_ENV === 'development';
    const urlCountry = hostWithoutProtocol.replace('www.', '')?.split('.')[1];
    const isCheckout = url.pathname.includes('/orders');
    const pathWithoutLocale = url.pathname.replace(urlLocaleMatcherRegex, '');

    const pathLocale = url.pathname.match(urlLocaleMatcherRegex)?.[0];

    const pathCountry = pathLocale?.split('-')[1];

    const cookieLocale = localStorage.getItem('pz-locale');
    const cookieCountry = cookieLocale?.split('-')[1];

    const defaultLocale =
      countries.find((c) => c.value === urlCountry)?.defaultLocale ??
      settings.localization.defaultLocaleValue;

    if ((!isCheckout && urlCountry != pathCountry || isDev) ) {
      const redirectLocale =  urlCountry && cookieCountry == urlCountry && !isDev ? cookieLocale : defaultLocale;
      localStorage.setItem(
        'pz-locale',
        redirectLocale
      );

      window.location.href = `${url.origin}/${
        redirectLocale
      }${pathWithoutLocale}`;
    }
  }, []);

  return <></>;
};

export default Page;
